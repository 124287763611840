import { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import "./editor.css";

const Editor = ({ onChange, data }) => {
  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(120);

  useEffect(() => {
    if (!editorRef.current) {
      const editor = new EditorJS({
        holder: "editorjs",
        placeholder: "Start typing here...",
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            config: {
              levels: [2, 3, 4],
              defaultLevel: 2,
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
        },
        data: data || {},
        onChange: async () => {
          const content = await editor.save();
          onChange({ target: { value: content } });
        },
      });

      editorRef.current = editor;
    }

    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [data, onChange]);

  return (
    <div
      ref={containerRef}
      className="editor-container"
      style={{ maxHeight: height }}
    >
      <div id="editorjs" className="editor-box"></div>
    </div>
  );
};

export default Editor;
