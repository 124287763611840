import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { deletePolicy } from "features/company/policiesActions";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";

const objInitialValues = {
  txtName: "",
  txtReason: "",
};
const ConfirmDeletionPolicyFormJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("Your name is required."),
  txtReason: Yup.string().required("A deletion reason is required."),
});

function DoubleConfirmationDialog({ open, onClose, onSubmit, policy }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtName,
      reason: values.txtReason,
    };

    let objUser = policy.user;
    let policyId = policy.element.id;

    setIsLoading(true);
    dispatch(deletePolicy({ objUser, policyId, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        showMsg("success", "Policy deleted successfully");
        onSubmit(response.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            `Failed to delete policy. ${rejectedValueOrSerializedError?.response?.data?.message || "Please try again."}`
        );
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Confirm Deletion</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={ConfirmDeletionPolicyFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="ConfirmPolicyDeletionForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Your Name"
                  name="txtName"
                  value={values.txtName}
                  placeholder={"Your Name"}
                  error={errors.txtName && touched.txtName}
                  success={values.txtName.length > 0 && !errors.txtName}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtName" />
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox mb={0} mt={1}>
                <Field
                  type="text"
                  label="Reason For Deletion"
                  name="txtReason"
                  value={values.txtReason}
                  placeholder={"Reason For Deletion"}
                  error={errors.txtReason && touched.txtReason}
                  success={values.txtReason.length > 0 && !errors.txtReason}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtReason" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="error"
                    sx={{ padding: "12px 12px" }}
                  >
                    Delete Policy
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default DoubleConfirmationDialog;
